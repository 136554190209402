<template>
  <section>
    <div>
      <h1>{{ pageTitle }}</h1>

      <!-- Breadcrumb -->
      <nav>
        <ol class="breadcrumb">
          <li v-for="(crumb, index) in breadcrumb" :key="index" class="breadcrumb-item">
            <span v-if="crumb.active">{{ crumb.text }}</span>
            <router-link v-else :to="crumb.to">{{ crumb.text }}</router-link>
          </li>
        </ol>
      </nav>

      <!-- Geri Butonu -->
      <button class="btn btn-secondary" @click="goBack">Geri</button>

      <!-- Yeni Kategori Ekleme Butonu -->
      <button class="btn btn-primary ml-2" @click="createModal = true">Yeni Kategori</button>

      <!-- Yeni Kategori Ekleme Formu -->
      <b-modal title="Yeni kategori ekle" centered hide-footer v-model="createModal">
        <div class="mt-4">

          <div class="form-group">
            <label for="name">Category Name</label>
            <input type="text" class="form-control" id="name" v-model="newCategory.name" required>
          </div>
          <div class="form-group">
            <label for="description">Category Description</label>
            <textarea class="form-control" id="description" v-model="newCategory.description" required></textarea>
          </div>
          <div class="form-group">
            <label for="image">Category Image</label>
            <input type="file" class="form-control-file" id="image" accept="image/*" @change="handleImageUpload">
          </div>
          <button type="submit" class="btn btn-primary" @click="addCategory">Add Category</button>
        </div>
      </b-modal>

      <!-- Kategori Düzenleme Modalı -->
      <b-modal title="Kategori Düzenle" centered hide-footer v-model="editModal">
        <div class="mt-4">
          <div class="form-group">
            <label for="editName">Category Name</label>
            <input type="text" class="form-control" id="editName" v-model="editCategoryData.name" required>
          </div>
          <div class="form-group">
            <label for="editDescription">Category Description</label>
            <textarea class="form-control" id="editDescription" v-model="editCategoryData.description"
              required></textarea>
          </div>
          <div class="form-group">
            <label for="editImage">Category Image</label>
            <input type="file" class="form-control-file" id="editImage" accept="image/*" @change="handleImageUpload">
          </div>
          <button type="submit" class="btn btn-primary" @click="updateCategory">Update Category</button>
        </div>
      </b-modal>


      <!-- Kategori Tablosu -->
      <div class="table-responsive mt-4">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th>Category Name</th>
              <th style="width: 22%;">Category Description</th>
              <th style="width: 15%;">Image</th>
              <th style="width: 30%; text-align: center;">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(category, index) in categories" :key="category.id">
              <td>{{ index + 1 }}</td>
              <td>{{ category.name }}</td>
              <td>{{ category.description }}</td>
              <td>
                <!-- Kategoriye ait resmin görüntülenmesi -->
                <img v-if="category.images" :src="category.images" alt="Category Image"
                  style="width: 100px; height: auto; object-fit: cover;">
              </td>
              <td style="display: flex; justify-content: space-around;">
                <router-link :to="`/second-page/${category.id}`" class="btn btn-sm btn-info">
                  Details
                </router-link>
                <button class="btn btn-sm btn-primary" @click="editCategory(category)">Edit</button>
                <button class="btn btn-sm btn-danger" @click="deleteCategory(category)">Delete</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>


<script>
import router from "@/router";
import categoryApi from "@/services/categoryApi";
import { BModal } from 'bootstrap-vue'

export default {
  components: {
    BModal
  },
  data() {
    return {
      createModal: false,
      editModal: false, // Düzenleme modalı
      categories: [], // Kategori listesi
      selectedId: '',
      pageTitle: "Kategori Yönetimi",
      breadcrumb: [
        { text: "Home", to: "/", active: false },
        { text: "Kategori Yönetimi", active: true },
      ],
      newCategory: {
        name: "",
        description: "",
        image: null,
      },

      editCategoryData: {
        id: null,
        name: "",
        description: "",
        image: null,
      },
    };
  },

  watch: {
    '$route.params.id': function (newId, oldId) {
      console.log('Yeni ID:', newId);
      console.log('Eski ID:', oldId);
      this.selectedId = newId

      if (newId === undefined) {
        categoryApi.getAllCategory().then((response) => {
          this.categories = response;
        });
      }
      if (newId !== undefined) {
        this.loadCategoryDetails(newId);
      }
    }
  },

  methods: {
    loadCategories() {
      if (this.selectedId !== undefined) {
        categoryApi.getCategoryDetails(this.selectedId).then((response) => {
          this.categories = response;
          //console.log("response",response)
        });
      } else {
        categoryApi.getAllCategory().then((response) => {
          this.categories = response;
        });
      }
    },
    editCategory(category) {
      // Düzenleme için kategori bilgilerini modalda gösterme
      this.editModal = true; // Modal'ı aç
      this.editCategoryData = { ...category };
      console.log(category)
    },

    loadCategories() {
      if (this.selectedId !== undefined) {
        categoryApi.getCategoryDetails(this.selectedId).then((response) => {
          this.categories = response;
          //console.log("response",response)
        });
      } else {
        categoryApi.getAllCategory().then((response) => {
          this.categories = response;
        });
      }
    },
    updateCategory() {
      // Kategori güncelleme işlemi
      const formData = new FormData();

      formData.append("id", this.editCategoryData.id);
      formData.append("name", this.editCategoryData.name);
      formData.append("description", this.editCategoryData.description);
      formData.append("image", this.editCategoryData.image);

      console.log("selectedId", this.selectedId);

      categoryApi.updateCategory(formData)
        .then((response) => {
          console.log("Kategori başarıyla güncellendi:", response);
          this.editModal = false; // Modal'ı kapat
          this.loadCategories(); // Güncelleme başarılıysa kategorileri yeniden yükle
        })
        .catch((error) => {
          console.error("Kategori güncellenirken hata oluştu:", error);
        });
    },

    clearUrlParams() {
      console.log(this.$route.params.id);

      // URL parametresini kaldırmak için replace kullanıyoruz
      if (this.$route.params.id) {
        this.$router.replace({ path: '/second-page' });
      }
    },
    loadCategoryDetails(id) {
      categoryApi.getCategoryDetails(id).then((response) => {
        this.categories = response;
      });
    },
    goBack() {
      // Geri gitmek için router.go(-1) metodunu kullanıyoruz
      this.$router.go(-1); // Bir önceki sayfaya git
    },
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.newCategory.image = reader.result;
          this.editCategoryData.image = reader.result;
        };
        reader.readAsDataURL(file);
      }
    },
    addCategory() {
      const formData = new FormData();
      // Form verilerini ekliyoruz
      formData.append("name", this.newCategory.name);
      formData.append("description", this.newCategory.description);
      const parentId = this.selectedId || '';
      formData.append("parentId", parentId);
      formData.append("image", this.newCategory.image);
      // Kategori ekleme işlemi
      categoryApi.createCategory(formData).then(() => {
        this.loadCategories(); // Kategorileri yeniden yükle
        this.createModal = false; // Modal'ı kapat

      });
    },
    deleteCategory(category) {
      // Kategori silme işlemi
      categoryApi.deleteCategory(category).then(() => {
        this.loadCategories(); // Kategorileri yeniden yükle
        alert("Kategori başarıyla silindi.");
      });
    }
  },

  mounted() {
    categoryApi.getAllCategory().then((response) => {
      this.categories = response;
    });

    //this.loadCategories();
    //this.clearUrlParams();
  },
};
</script>
